.color-yellow {
    color: #FFA800;
}

.color-blue {
    color: #69A5FF;
}

.color-orange {
    color: #FFA800;
}

.lottrey_row {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 45px 0 20px 0;
}

.lattery_colum {
    position: relative;
    z-index: 1;
    padding: 33px 50px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    height: 169px;
    align-items: center;
}

.HomepageWrapArea> .container {
    max-width: 920px;
}

.spinnLattery {
    background-image: url(assets/img/new/homepage/homebg-1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.max3dLattery {
    background-image: url(assets/img/new/homepage/homebg-2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.jackpotLattery {
    background-image: url(assets/img/new/homepage/homebg-3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.lattery_colum h2 {
    font-weight: 800;
    font-size: 51px;
    line-height: 1;
    margin: 0;
}

.lattery_colum h4 {
    font-weight: 800;
    font-size: 29px;
    line-height: 1;
    color: #FFE400;
    margin: 0;
}

img.SpinnImg {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    max-width: 65%;
    object-position: bottom;
    object-fit: cover;
}

img.BallImg {
    display: block;
    position: absolute;
    left: 7%;
    bottom: 0;
    height: 90%;
}

.lattery_colum.max3dLattery {
    justify-content: flex-end;
}

.lattery_colum.jackpotLattery {
    background-size: 100% 100%;
}

img.JackpotImg {
    position: absolute;
    z-index: -1;
    right: 3%;
    bottom: 0;
    height: 100%;
}

img.jackpotbg {
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
    height: 100%;
    mix-blend-mode: color-dodge;
    opacity: 0.4;
}

.lattery_colum.jackpotLattery> div p {
    font-weight: 400;
    font-size: 17.9849px;
    line-height: 25px;
    margin: 0;
    letter-spacing: 0.005em;
    color: #FFE400;
    margin-top: 5px;
}

.color-w {
    color: #fff;
}

.time {
    display: flex;
    gap: 10px;
}

.lattery_colum.jackpotLattery .time> p {
    font-weight: 800;
    font-size: 18.9849px;
    line-height: 26px;
    margin: 0;
    color: #fff;
}

.lattery_colum.jackpotLattery .time> p span {
    color: #FFE400;
    font-size: 12px;
}

.lattery_colum.jackpotLattery .timeRemaining> p {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    margin-top: -10px;
}

.timeRemaining {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 18%;
    bottom: 5px;
}

.lattery_colum.jackpotLattery> div p span {
    font-weight: 800;
}

.lattery_colum.max3dLattery div {
    text-align: right;
}

.LotterypageWrap .container {
    max-width: 1210px;
}

.blueCard {
    background: linear-gradient(180deg, #0E1D3A 0%, #0E1D3A 100%);
    border: 1.00156px solid #3B5585;
    border-radius: 10.0156px;
    padding: 15px;
    position: relative;
    z-index: 1;
}

.timeCountDown {
    position: absolute;
    z-index: 3;
    display: flex;
    right: 20px;
    gap: 5px;
    align-items: center;
}

.timeCountDown p {
    margin: 0;
    font-weight: 400;
    font-size: 30px;
    line-height: 1;
    color: #FFA800;
}

.timeCountDown p span {
    font-weight: 400;
    font-size: 18px;
    color: #fff;
}

.selectNumberLott {
    position: relative;
    z-index: 1;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    padding: 34px 0;
}

.selectNumberLott h2 {
    font-weight: 400;
    font-size: 50px;
    line-height: 1;
    margin: 0 0 5px 0;
}

.selectNumberLott p {
    margin: 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #7AA0E9;
}

.selectNumberLott:after {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    padding-bottom: 84%;
    content: "";
    background: linear-gradient(180deg, #203660 -0.57%, rgba(19, 37, 71, 0) 39.18%, rgba(19, 37, 71, 0) 40.79%);
    border-radius: 50%;
    pointer-events: none;
}

.rangeInput {
    background: #0E1D3A;
    border: 1.41574px solid #3B5585;
    border-radius: 13.3542px;
    padding: 26px 35px;
    /* max-width: 615px; */
    /* margin: 0 auto; */
}

.rangeInput input {
    display: block;
    width: 100%;
}

.rangeNumber {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 0px 10px;
}

.numberRange {
    max-width: 615px;
    margin: 0 auto;
}

.rangeNumber p {
    margin: 0;
    line-height: 2;
    color: #7AA0E9;
    font-size: 16px;
}

.lotteryBeat {
    max-width: 530px;
    margin: 10px auto 0 auto;
}

.beat_amounts {
    background: #0E1D3A;
    border: 0.667708px solid #3B5585;
    border-radius: 10.0156px;
    padding: 10px 15px 10px 15px;
}

.amoiunt_to_beat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 15.3573px;
    line-height: 26px;
}

.amoiunt_to_beat button {
    font-weight: 400;
    font-size: 12.0077px;
    line-height: 20px;
    color: #7AA0E9;
    width: 75%;
    max-width: 110px;
    background: transparent;
    border: 1px solid #6483BF;
    border-radius: 4.99455px;
    padding: 3px;
}

.beat_amounts .numberRange {
    padding-top: 8px;
}

.numberRange> input {
    display: block;
    width: 100%;
}

.beat_amounts .rangeNumber {
    padding: 5px 0 0 0;
    line-height: 1;
}

.dualButton {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 8px;
}

.dualButton> button {
    font-weight: 400;
    font-size: 13.8523px;
    line-height: 23px;
    display: flex;
    height: 33px;
    flex-grow: 1;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 0.809175px solid #3D5C99;
    border-radius: 5px;
    color: #fff;
    background: rgba(0, 0, 0, 0.3);
    transition: .3s ease all;
    outline: none;
    box-shadow: none;
}

.dualButton> button.active,
.dualButton> button:hover {
    background: rgba(104, 150, 240, 0.3);
}

.dualButton> input {max-width: calc(50% - 5px);}

.buyTicketsBtn {
    font-weight: 400;
    font-size: 16.6927px;
    line-height: 28px;
    display: flex;
    width: 100%;
    background-color: transparent;
    color: #fff;
    text-align: center;
    justify-content: center;
    height: 54px;
    border: 0;
    align-items: center;
    cursor: pointer;
    background: url(assets/img/new/Frame.png);
    background-size: 100% 100%;
}

.beat_amounts .rangeNumber p {
    line-height: 1;
}

.how_to_playBox> h2 {
    font-weight: 400;
    font-size: 25px;
    line-height: 42px;
    margin-bottom: 12px;
}

.howToPlayCard {
    text-align: center;
    background: rgb(44 71 121 / 20%);
    border-radius: 9.7939px;
    padding: 35px 12px;
    margin-bottom: 15px;
}

.howToPlayCard h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}


.howToPlayCard p {
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    color: #7C96CA;
    margin: 0;
}

.how_to_playBox h5 {
    font-weight: 400;
    font-size: 17px;
    line-height: 29px;
}

.LotterypageWrap .bettingList {
    height: 360px;
    max-height: unset;
    padding: 0;
    min-height: unset;
    overflow: auto;
}

.LotterypageNumber2 .timeCountDown {
    position: unset;
    max-width: fit-content;
    margin: 0 auto;
}

.selectNumberLott.selectNumberLottBtns:after {
    display: none;
}

.selectNumberLottBtnsList {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
    padding-bottom: 10px;
}

.selectNumberLottBtnsList input,
.selectNumberLottBtnsList button {
    height: 55px;
    background: #203660;
    border-radius: 10px;
    border: 0;
    color: #fff;
    flex-grow: 1;
    max-width: 169px;
    text-align: center;
}

input.disable {
    border-radius: 10px;
    opacity: 0.5;
    background: linear-gradient(180deg, #203660 8.86%, rgba(19, 37, 71, 0.50) 100%);
    pointer-events: none;
}

.selectNumberLott.selectNumberLottBtns {
    max-width: 530px;
    padding: 24px 0;
}

.how_to_playBox {
    margin-top: 25px;
}

button.opacity5 {
    background: linear-gradient(180deg, #203660 -0.57%, rgba(19, 37, 71, 0.5) 100%);
    opacity: 0.5;
}

.selectNumberLott> h4 {
    font-weight: 400;
    font-size: 17.9572px;
    line-height: 30px;
    margin: 0;
}

.ResultPageWrape3> .container {
    max-width: 658px;
}

.resultTop {
    display: flex;
    justify-content: space-between;
}

.resultCol {
    background: rgba(3, 6, 12, 0.2);
    border-radius: 8.39286px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 25px 28px;
}

.resultCol> p {
    white-space: nowrap;
    margin: 0;
    margin-left: 30px;
    color: #6483BF;
}

.resultCol .selectNumberLottBtnsList button {
    background: rgba(86, 141, 255, 0.3);
}

.resultCol .selectNumberLottBtnsList {
    margin: 0;
    padding: 0;
}

.bettingList table.table thead {
    background: #132547;
}

.bettingList table.table tr td, .bettingList table.table tr th {
    padding: 10px 25px !important;
}

p.starNumber {
    display: flex;
    align-items: center;
    line-height: 1;
}

p.starNumber img {
    margin-right: 7px;
}

p.starNumber span {
    color: #FFA800;
    font-weight: 400;
    font-size: 22px;
    line-height: 37px;
    margin-right: 5px;
}

.select_box select {
    border: 0.75px solid #6483BF;
    border-radius: 5.20952px;
    background: transparent;
    color: #6483BF;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    padding: 5px 15px;
}

.resultNumberItem {
    position: relative;
    z-index: 1;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: rgba(3, 6, 12, 0.2);
    border-radius: 8.39286px;
    font-weight: 400;
    font-size: 15.1071px;
    line-height: 26px;
    color: #5D74A3;
    width: calc(10% - 6px);
    transition: .3s ease all;
}

.ResultPageWrape .resultContainer {
    max-width: 564px;
    margin: 0 auto;
}

.result_number {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

button.resultNumberItem:hover, 
button.resultNumberItem.active {
    color: #FFFFFF;
    background: rgba(86, 141, 255, 0.3);
}

.resultNumberItem span {
    position: absolute;
    font-size: 11px;
    line-height: 1;
    color: #FFA800;
    right: 5px;
    top: 5px;
}

.resultNumberItem.activeWiner {
    background: #FFA800 !important;
    color: #fff !important;
}

.ResultPageWrape2 .container {
    max-width: 971px;
}

.resultCartDate {
    text-align: right;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #6483BF;
}

.resultCart {
    background: rgba(3, 6, 12, 0.2);
    border-radius: 8.39286px;
    padding: 12px 20px 20px 25px;
}

.resultColum {
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin-right: 15px;
}

.resultTitle {
    font-weight: 400;
    font-size: 15.1071px;
    line-height: 26px;
    width: 125px;
    margin-right: 15px;
    flex-shrink: 0;
}

.winingPrice {
    margin-left: auto;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #FFA800;
    flex-shrink: 0;
}

.resultColum .result_number {
    flex-grow: 1;
    padding-right: 50px;
}

.resultColum .result_number button {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(86, 141, 255, 0.3);
    border-radius: 8.39286px;
    border: 0;
    color: #fff;
    font-weight: 400;
    font-size: 15.1071px;
    line-height: 1;
    width: calc(12% - 6px);
}

.resultColum .result_number.startResult button {
    background: #FFA800;
}

.resultColum .result_number.startResult button img {
    margin-right: 2px;
}

.lotteryBeat .row> div {
    width: 50% !important;
    max-width: 50% !important;
}

.resultPageWrap .userBettingList {
    margin: 0 !important;
}

.buy_ticket_box {
    display: flex;
    align-items: center;
}

.buy_ticket_box p {
    margin: 10px 10px 10px 0px;
    flex-grow: 1;
}

.buy_ticket_box button.buyTicketsBtn {
    width: 50%;
    max-width: 193px;
}

.lotteryBtn .navbar-nav .nav-link:nth-child(2) {
    margin: 0 !important;
}

.lotteryBtn .navbar-nav {
    justify-content: flex-end;
}

.navbar-nav .gameMenu.nav-link, .navbar-nav a.sportMenu.nav-link {
    height: 40px;
    padding: 7px 30px !important;
}

.lotteryBtn a.walletLink, .lotteryBtn a.BlueBtn {
    margin: 0 0 0 10px;
}

.lotteryBtn .lottryPageBtnNone {
    display: none;
}

.affiliaeBtn img {
    filter: unset;
}

.myticletPageWrap a.nav-link.sportMenu.myTicketsLink,
.resultPage a.nav-link.sportMenu.resultHisLink {
    background: #0066FF;
}

.myticletPageWrap .container {
    max-width: 1280px;
    padding-left: 45px;
    padding-right: 45px;
}

.myticletPageWrap .userBettingList {
    margin-top: 0 !important;
}

.LotterypageWrap.lotteryBtn .lottryPageBtnNone {
    display: inline;
}

.LotterypageWrap.lotteryBtn .lottryPageBtn.tablet-none {
    display: none !important;
}

.userBettingList.userBetLott3 span.color-blue {
    white-space: nowrap;
}

.userBettingList.userBetLott3 table.table tr td, 
.userBettingList.userBetLott3 table.table tr th {
    padding: 10px 15px !important;
}

.userBettingList.userBetLott3 table.table tr td:last-child {
    min-width: 105px;
}

.amoiunt_to_beat .theAmount input {
    max-width: 32px;
}